@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

* {
  font-family: "Nunito", sans-serif;
}

.c-title {
  font-family: "Bungee Spice", cursive;
}

.c-input {
  color: rgba(0, 0, 0, 0.88) !important;
}

.watermark {
  position: absolute;
  font-family: "Bungee Spice", cursive;
  opacity: 0.15;
  top: 0;
  left: 0;
  font-size: 4rem;
  width: 100%;
  text-align: center;
  margin-top: 4rem;
  z-index: 0;
  /*transform: rotate(-30deg);*/
}

.ff-pricing {
  font-family: "Fjalla One", sans-serif;
}

@layer components {
  .custom-password,
  .custom-password input[type="password"],
  .custom-password input[type="text"] {
    @apply dark:bg-gray-500 dark:border-gray-400 dark:text-white dark:placeholder:text-gray-400;
  }

  .white-text i {
    color: white;
  }

  .c-footer {
    @apply border-solid border-gray-300 border-t border-b-0 border-x-0;
  }

  .bg-neutral-200:hover i {
    @apply text-neutral-500;
  }
}
